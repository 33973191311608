.vjs-label-hidden {
  display: none!important;
}

.cld-video-player .vjs-control-bar .vjs-control.vjs-ads-label {
        line-height: 3em;
        font-weight: normal;
        text-align: center;
        display: none;
        width: auto;
        padding: 0 1em;
      }

.cld-video-player.vjs-ad-playing .vjs-control.vjs-ads-label {
    display: block;
  }
