@import '../../../assets/styles/mixins/mixins.scss';

@mixin listLayout() {
    .cld-plw-layout {
        &.cld-plw-vertical, &.cld-plw-horizontal {
            flex-direction: column;

            .cld-plw-panel {
                flex-direction: column;
            }

            .cld-plw-col-list {
                width: inherit !important;
                height: inherit !important;
                max-height: 340px;
            }

            .cld-plw-panel-item {
                background: none !important;

                img {
                    display: block;
                    width: 150px;
                    height: 84px;
                }

                .cld-plw-item-info-wrap {
                    left: 150px;
                    padding: 15px 10px;
                    top: 0;
                    right: 0;
                    width: auto;
                }

                .cld-plw-item-title-next {
                    display: block;
                }

                .cld-plw-item-title-curr {
                    display: block;
                }

                .cld-plw-item-title {
                    white-space: normal;
                }
            }
        }

        &.cld-plw-horizontal {
            .cld-plw-col-list {
                padding-top: 0;
                overflow: auto;
            }

            .cld-plw-panel {
                position: initial;
            }

            .cld-plw-panel-item {
                max-width: none;
            }
        }
    }
}

.cld-plw-horizontal {
    .cld-plw-col-list {
        padding-top: 14.0625%;
        position: relative;
    }

    .cld-plw-panel {
        overflow: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        flex-direction: row;
    }

    .cld-plw-panel-item {
        background-image: none;
        min-width: 25%;
        max-width: 25%;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}


.cld-plw-vertical {

    display: flex;
    flex-direction: row;

    &.cld-plw-layout {
        > div:first-child {
            flex: 1;
        }
    }

    .cld-plw-col-list {
        display: flex;
        flex-direction: column;
        min-width: 20%;
    }

    .cld-plw-panel {
        height: 100%;
        overflow: auto;
    }

    .cld-plw-panel-item {
        height: 25%;

        &:last-child {
            margin-bottom: 0;
        }

    }
}

.cld-plw-item-title-next {
    font-weight: bold;
}

.cld-plw-item-info-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5% 7%;
    width: 100%;
    transition: color .25s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;

    .cld-video-player-skin-dark & {
        text-shadow: 1px 1px 1px rgba(#000, .3);

        @media only screen and (max-width: 768px) {
            background: #272727;
        }
    }
}

.cld-plw-item-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @include ellipsis;
}

.cld-plw-item-duration {
    margin-left: 10px;
}

/*  Default Playlist Layout */

.cld-plw-layout {
    &.cld-plw-layout-fluid {
        width: 100%;
    }

    &.cld-plw-vertical {
        flex-direction: row;
    }

    &.cld-plw-horizontal {
        flex-direction: column;
    }
}

.cld-plw-custom {
    padding: 4px;
    .cld-plw-panel-item {
        background: none !important;
        margin: 4px 0;
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        img {
            display: block;
            width: 150px;
        }

        .cld-plw-item-info-wrap {
            left: 160px;
        }

        .cld-plw-item-title-next {
            display: block;
        }
    }
}

@media only screen and (max-width: 768px) {
    @include listLayout();
}
