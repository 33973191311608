.video-js {
  $size: 20px;
  $height: 1.2em;
  $width: 6em;

  %triangle-volume-bar {
    content: '';
    font-size: $size;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    line-height: 0px;
    border-style: solid;
    border-width: 0 0 $height $width;
    border-color: transparent transparent #0e2f5a transparent;
  }

  .vjs-volume-panel-horizontal {

    // Background triangle
    .vjs-volume-control {
      overflow: hidden;
      height: 3em;

      &:before {
        @extend %triangle-volume-bar;
      }
    }

    // Inner Container
    .vjs-volume-bar {
      margin: 0;
      height: 100%;
      background-color: transparent;
    }

    // Inner triangle
    .vjs-volume-level {
      overflow: hidden;
      height: 100%;
      background: transparent;
      box-shadow: none;

      &:before {
        @extend %triangle-volume-bar;
        border-color: transparent transparent #e8e8e9 transparent;
      }
    }
  }
}
