.cld-video-player {

  .vjs-time-tooltip,
  .vjs-mouse-display:after,
  .vjs-play-progress:after {
    font-weight: 300;
    padding: 0.4em 0.6em;
    top: -2.6em;
  }

  .vjs-recommendations-overlay .vjs-recommendations-overlay-item:active:after,
  .vjs-recommendations-overlay .vjs-recommendations-overlay-item:hover:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .vjs-context-menu-ui .vjs-menu-content .vjs-menu-item {
    text-align: left;
  }

  &.vjs-ad-playing .vjs-play-control.vjs-paused,
  &.vjs-ad-playing .vjs-volume-level {
    background-color: transparent !important;
  }

  &.vjs-ad-playing .vjs-play-progress:before {
    display: none;
  }

}

.cld-video-player.cld-video-player-skin-light {

  .video-js button:hover,
  .vjs-volume-menu-button:hover {
    opacity: 0.9;
  }

  .vjs-title-bar .vjs-title-bar-title:not(:empty) +
  .vjs-title-bar-subtitle:not(:empty):before {
    content: "\2022";
    padding: 0 0.45em;
    font-size: 1.4em;
    font-weight: 500;
  }

  .vjs-title-bar {
    flex-direction: row;
    justify-content: left;
    height: 3.6em;

    > div {
      width: auto;
      padding: 0;
      margin: 0;
    }
  }

}
