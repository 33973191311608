
.video-js .vjs-title-bar {
  display: none;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 7.2em;
  text-align: left;
  padding: 16px 21px;
  pointer-events: none;

  .vjs-title-bar-title {
    width: 100%;
    font-size: 1.2em;
    font-weight: 500;
    line-height: 1.2em;
    margin-bottom: 0.55em;
  }

  .vjs-title-bar-subtitle {
    width: 100%;
    font-size: 1em;
    font-weight: 300;
    line-height: 1em;
  }
}

// Video has started playing
.vjs-has-started .vjs-title-bar {
  display: flex;
  flex-direction: column;
  align-items: center;

  visibility: visible;
  opacity: 1;

  transition: visibility 0.1s, opacity 0.1s; // Var needed because of comma
}

// Video has started playing AND user is inactive
.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-title-bar {
  // Remain visible for screen reader and keyboard users
  visibility: visible;
  opacity: 0;

  transition: visibility 1.0s, opacity 1.0s;
}

.vjs-controls-disabled .vjs-title-bar,
.vjs-using-native-controls .vjs-title-bar,
.vjs-error .vjs-title-bar,
.vjs-ad-playing .vjs-title-bar {
  // !important is ok in this context.
  display: none !important;
}

// Don't hide the control bar if it's audio
.vjs-audio.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-title-bar {
  opacity: 1;
  visibility: visible;
}

// IE 8 + 9 Support
.vjs-has-started.vjs-no-flex .vjs-title-bar {
  display: table;
}
